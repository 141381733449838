import React, { useEffect } from 'react'

import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

import { FormContainer, TextFieldElement, PasswordElement } from 'react-hook-form-mui'
import { Typography, Grid, Button, Divider, Alert } from "@mui/material";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Link } from '@components/Link'

import { setCredentials } from '@store/slices/auth.slice'
import { useLoginMutation } from '@store/api/user.api.graphql'
import { useAppDispatch } from '@store/configureStore'

import { useAuth } from '@lib/auth'

const cleverClientId = process.env.GATSBY_CLEVER_CLIENT_ID;
const cleverRedirectUri = process.env.GATSBY_CLEVER_REDIRECT_URI;
const cleverUrl = `https://clever.com/oauth/authorize?response_type=code&client_id=${cleverClientId}&redirect_uri=${cleverRedirectUri}`;

const LoginPage = () => {
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  const tokenError = urlParams.get('error');
  const tokenUser = urlParams.get('user');

  // Function to handle the token
  const handleTokenLogin = () => {
    if (token) {
      // todo: Find a more elegant way to get the user information from the token
      const user = tokenUser ? JSON.parse(tokenUser) : {}; // Fetch user information using the token or set to an empty object
      dispatch(setCredentials({ jwt: token, user }));
      navigate(redirect);
    } else if (tokenError) {
      setError(tokenError);
    }
  };

  // Call the handleTokenLogin function when the component mounts
  useEffect(() => {
    if (token || tokenError) {
      handleTokenLogin();
    }
  }, [token, tokenError]);

  const { isLoggedIn } = useAuth()

  const redirect = location.state?.redirect || '/'

  const [login, { isLoading, data, error }] = useLoginMutation()

  useEffect(() => {
    if (isLoggedIn) {
      navigate(redirect)
    }
  })

  const dispatch = useAppDispatch()

  const handleSubmit = async credentials => {
    const { data, error } = await login(credentials)

    if (error) {
      return
    }

    const { jwt, user } = data.login;

    if (jwt) {
      dispatch(setCredentials({ jwt, user }))
    }
  }

  const schema = Yup.object().shape({
    identifier: Yup.string().required().email(),
    password: Yup.string().required(),
  })

  const resolver = yupResolver(schema)

  return (
    <FormContainer onSuccess={handleSubmit} resolver={resolver}>
      <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={2}>
        <Grid item>
          <Typography variant="x-headline2-black">Login</Typography>
        </Grid>

        <Grid item>
          <TextFieldElement
            name="identifier"
            label={'Email'}
            fullWidth
            autoComplete={'email'}
            variant={'standard'}
            color={'secondary'}
            size={'large'}
            autoFocus
          />
        </Grid>

        <Grid item>
          <PasswordElement
            name="password"
            label="Password"
            autoComplete={'current-password'}
            variant={'standard'}
            color={'secondary'}
            size={'large'}
            fullWidth
          />
        </Grid>

        <Grid item>
          <Button type={'submit'} variant="contained" color="primary" fullWidth size={'large'} disabled={isLoading}>
            Login
          </Button>
        </Grid>

        {error && (
          <Grid item>
            <Alert severity="error" variant='outlined'>{error.data?.message || 'An error occurred'}</Alert>
          </Grid>
        )}

        <Grid item>
          <Link to="/auth/forgot">I forgot my password</Link>
        </Grid>

        <Grid item sx={{ mt: 2, pb: 2 }}>
          <Divider />
        </Grid>

        <Grid item>
          <Button variant="contained" color="tertiary" fullWidth size={'large'} disabled={isLoading} href={cleverUrl}>
            Login with Clever
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

export default LoginPage
