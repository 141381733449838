import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
import { Paper } from '@components/Paper'
import { Logo } from '@components/Logo/Logo'

const AuthLayout = ({ children }) => {
  return (
    <Grid
      container
      spacing={0}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'stretch'}
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        m: 0,
        p: 0,
      }}
    >
      <Grid
        item
        sx={{
          height: '110px',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid rgba(39, 39, 39, 0.1)',
        }}
      >
        <Logo alt={'RoboWunderkind'} height={'40'} width={'40'} sx={{ marginTop: '35px', marginLeft: '35px' }}  />
      </Grid>

      <Grid
        item
        sx={{ height: 'calc(100vh - 110px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Container>
          <Paper variant={'filled'} sx={{ p: 4, backgroundColor: '#ebebeb' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: '100%', ml: 5, mr: 5 }}>{children}</Box>
              </Grid>

              <Grid item xs={6}>
                <StaticImage src="../assets/images/splash.png" alt={'Hello at Robo'} />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  )
}

export default AuthLayout
