import React from 'react'

import { FormContainer, TextFieldElement, PasswordElement, CheckboxElement } from 'react-hook-form-mui'
import { Typography, Grid, Button, Divider } from '@mui/material'

const RegisterPage = () => {
  return (
    <FormContainer>
      <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={2}>
        <Grid item>
          <Typography variant="x-headline2-black">Welcome to Robo Wunderkind!</Typography>
        </Grid>

        <Grid item>
          <TextFieldElement name="email" label={'Email'} required fullWidth />
        </Grid>

        <Grid item>
          <PasswordElement name="password" label={'Password'} required fullWidth />
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" fullWidth>
            Create an account
          </Button>
        </Grid>

        <Grid item>
          <Typography>Password must be at least 8 characters</Typography>
        </Grid>

        <Grid item>
          <CheckboxElement
            name="allow-communications"
            label={'I agree to receive occasional related communication'}
          />
        </Grid>

        <Grid item>
          <CheckboxElement name="agree-terms" label={'I accept Privacy Policy and Terms & Conditions'} required />
        </Grid>

        <Divider />

        <Grid item>
          <Button variant="contained" color="tertiary" fullWidth>
            Sign in with Google Classroom
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" color="tertiary" fullWidth>
            I already have an account
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

export default RegisterPage;
