import React from 'react'

import { FormContainer, PasswordElement, PasswordRepeatElement } from 'react-hook-form-mui'

import { Typography, Grid, Button } from '@mui/material'

const EnterPasswordPage = () => {
  return (
    <FormContainer>
      <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={2}>
        <Grid item>
          <Typography variant="x-headline2-black">Password </Typography>
        </Grid>

        <Grid item>
          <Typography> Password must be at least 8 characters </Typography>
        </Grid>

        <Grid item>
          <PasswordElement name="password" label={'Password'} required fullWidth />
        </Grid>

        <Grid item>
          <PasswordRepeatElement
            name="password-confirmation"
            passwordFieldName="password"
            label={'Confirm new password'}
            required
            fullWidth
          />
        </Grid>

        <Grid item>
          <Button variant="contained" color="secondary">
            Back
          </Button>
          <Button variant="contained" color="primary">
            Send
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

export default EnterPasswordPage;
