import React from 'react'
import { Router } from '@reach/router'

import { PublicRoute } from '@lib/route'

import Layout from '@auth/layouts/layout'

import Seo from '@components/seo'


import EnterPassword from '@auth/pages/enter-password'
import Forgot from '@auth/pages/forgot'
import Login from '@auth/pages/login'
import Logout from '@auth/pages/logout'
import Register from '@auth/pages/register'
import Verify from '@auth/pages/verify'

const AuthPage = () => {
    return (
        <Layout>
            <Seo seo={{ metaTitle: 'Auth' }} />

            <Router basepath="/auth" style={{ height: '100%' }}>

                <PublicRoute path="/enter-password" component={EnterPassword} />
                <PublicRoute path="/forgot" component={Forgot} />
                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/logout" component={Logout} />
                <PublicRoute path="/register" component={Register} />
                <PublicRoute path="/verify" component={Verify} />

            </Router>
        </Layout>
    )
}

export default AuthPage;
