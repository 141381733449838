import React from 'react'

import { FormContainer, TextFieldElement } from 'react-hook-form-mui'

import { Typography, Grid, Button } from '@mui/material'

const VerifyPage = () => {
  return (
    <FormContainer>
      <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={2}>
        <Grid item>
          <Typography variant="x-headline2-black"> Verify your email </Typography>
        </Grid>

        <Grid item>
          <Typography>
            Please enter the email you used to create Robo Wunderkind account, so we can send you a code
          </Typography>
        </Grid>

        <Grid item>
          <TextFieldElement name="n1" required inputProps={{ maxLength: 1 }} />
          <TextFieldElement name="n2" required inputProps={{ maxLength: 1 }} />
          <TextFieldElement name="n3" required inputProps={{ maxLength: 1 }} />
          <TextFieldElement name="n4" required inputProps={{ maxLength: 1 }} />
        </Grid>

        <Grid item>
          <Button variant="contained" color="secondary">
            Back
          </Button>
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

export default VerifyPage;
