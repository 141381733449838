import React, { useLayoutEffect } from 'react'

import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

import { clearCredentials } from '@store/slices/auth.slice'
import { useAppDispatch } from '@store/configureStore'

const LogoutPage = () => {
  const location = useLocation()

  const redirect = location.state?.redirect || '/auth/login'
  const dispatch = useAppDispatch()

  dispatch(clearCredentials())

  useLayoutEffect(() => {
    navigate(redirect)
  }, [])

  return <>Logging out...</>
}

export default LogoutPage