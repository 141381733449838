import React from 'react'
import { navigate } from 'gatsby'

import { FormContainer, TextFieldElement } from 'react-hook-form-mui'

import { Typography, Grid, Button } from '@mui/material'

const ForgotPage = () => {
  const handleBackClick = () => {
    navigate('/auth/login')
  }

  return (
    <FormContainer>
      <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={2}>
        <Grid item>
          <Typography variant="x-headline2-black"> Please enter your email </Typography>
        </Grid>

        <Grid item>
          <Typography> account, so we can send you a code </Typography>
        </Grid>

        <Grid item>
          <TextFieldElement name="email" label={'Email'} variant={'standard'} size={'large'} required fullWidth />
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth color="secondary" onClick={handleBackClick}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth color="primary">
              Send
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

export default ForgotPage
